import {
  Box,
  Heading as GrommetHeading,
  Heading,
  Paragraph,
  ResponsiveContext
} from 'grommet'
import { DirectionType } from 'grommet/utils'
import Image, { StaticImageData } from 'next/image'
import { useContext } from 'react'
import styled from 'styled-components'
import botskiArriving from 'public/images/botski-arriving.png'
import botskiDriving from 'public/images/botski-driving.png'
import botskiDistance from 'public/images/botski-distance.png'
import botskiWeather from 'public/images/botski-weather.png'
import botskiDrink from 'public/images/botski-drink.png'
import { useWindowSize } from 'hooks/useWindowSize'

const Instructions = () => {
  return (
    <Box id="ohjeet" pad="large" background="#2651CA" gap="large">
      <Box align="center">
        <GrommetHeading
          level="2"
          color="white"
          margin={{ vertical: 'medium', left: 'large' }}
          textAlign="center"
        >
          Veneily&shy;ohjeet
        </GrommetHeading>
      </Box>
      <InstructionSection
        title="Saapuminen botskille"
        text="Veneilyn aloitus ja päätepiste on Hakaniemessä Eläintarhanlahdella (Eläintarhantie 10, Helsinki). Saavu paikalle 15 min ennen varauksen alkua, niin ehditte rauhassa avata lukot ja tutustua veneeseen. Voit ja suosittelemme tuomaan omat eväät veneelle."
        imgSrc={botskiArriving}
      />
      <InstructionSection
        title="Veneen ohjaus"
        text="Veneessä on sähkömoottori, ruori ja nopeuden säädin, joiden avulla ohjaaminen on yksinkertaista. Vähintään yhdellä veneessä olevalla tulee olla aikaisempaa veneilykokemusta. Annamme mielellämme myös sopivia reittisuosituksia."
        imgSrc={botskiDriving}
      />
      <InstructionSection
        title="Turvallisuus"
        text="Kun olet vesillä pidä turvaetäisyydet muihin veneisiin ja rannikkoon. Hyvänä nyrkkisääntönä on pitää vähintään 25-50 metrin etäisyys rantaan. Aja aina väylän keskellä, väistä purjeveneitä, vastaan tulevia veneitä väistetään oikealle, niin kuin autoillessa. Pidä iso välimatka muihin veneisiin ja todella iso välimatka laivoihin. Stadin botski sopii myös lapsille ja voit tuoda nelijalkaiset ystäväsi mukaan, mutta pelastusliivejä meiltä löytyy vain aikuisille."
        imgSrc={botskiDistance}
      />
      <InstructionSection
        title="Sää"
        text="Tärkeintä veneilyssä on lähteä vesille vain hyvällä säällä. Veneily ei ole kovin kivaa sateella tai kovalla tuulella. Jos merellä on kovan tuulen varoitus, niin varaus siirretään. Voit halutessasi siirtää varauksen, mikäli ilmoitat siitä vähintään 24 tuntia ennen sovittua lähtöä. Muissa tapauksissa voit peruuttaa varauksen 72 tuntia ennen varausta."
        imgSrc={botskiWeather}
      />

      <InstructionSection
        title="Älä veneile humalassa"
        text="Omien virvokkeiden nauttiminen kohtuudella on suotavaa, mutta veneen kuljettajan tulee olla selvin päin. Venettä ei luovuteta humaltuneelle kipparille. Muista myös ottaa omat piknikeväät mukaan. Ruoka ja elämä maistuu paremmalta, kun sen nauttii merellä."
        imgSrc={botskiDrink}
      />
    </Box>
  )
}
export default Instructions

function useGetImageWidth() {
  const { width: windowWidth } = useWindowSize()
  const paddings = 24 * 2 // 2 x large paddings
  const minWidth = 440 + paddings

  if (!windowWidth) return 440
  if (windowWidth < minWidth) return windowWidth - paddings
  return 440
}

const InstructionSection = ({
  title,
  text,
  imgSrc
}: {
  title: string
  text: string
  imgSrc: StaticImageData
}) => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  const direction: DirectionType = isSmall ? 'column' : 'row'
  const width = useGetImageWidth()

  return (
    <Box
      direction={direction}
      align="center"
      style={isSmall ? { maxWidth: width } : {}}
    >
      <div style={{ height: 332 }}>
        <RoundishImage width={width}>
          <Image
            fill={true}
            style={{ objectFit: 'contain', borderRadius: '20px' }}
            src={imgSrc}
            placeholder="blur"
            alt=""
          />
        </RoundishImage>
      </div>
      <Box
        margin={isSmall ? { horizontal: 'large' } : { left: 'large' }}
        width="large"
      >
        <Heading
          textAlign={isSmall ? 'center' : 'start'}
          level="4"
          margin={isSmall ? { vertical: 'large' } : {}}
          color="white"
        >
          {title}
        </Heading>
        <Paragraph
          textAlign={isSmall ? 'center' : 'start'}
          margin={isSmall ? { bottom: 'xlarge' } : { vertical: 'small' }}
          fill
        >
          {text}
        </Paragraph>
      </Box>
    </Box>
  )
}

const RoundishImage = styled.div<{ width: number }>`
  width: ${p => p.width}px;
  position: relative;
  height: 100%;
`
