import {
  Box,
  Heading as GrommetHeading,
  Paragraph,
  ResponsiveContext
} from 'grommet'
import { DirectionType } from 'grommet/utils'
import Image from 'next/image'
import Link from 'next/link'
import instagram from 'public/icons/instagram.svg'
import { useContext } from 'react'

const Contact = () => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  const direction: DirectionType = isSmall ? 'column' : 'row'
  const textAlign = isSmall ? 'center' : 'start'
  const align = isSmall ? 'center' : 'start'
  return (
    <Box
      id="yhteystiedot"
      background="#2348B2"
      pad={{ top: 'xlarge', bottom: 'large', right: 'large', left: 'large' }}
    >
      <Box direction={direction} justify="between" align="center">
        {isSmall ? (
          <Box margin="xlarge" pad={{ bottom: 'large' }}>
            <Logo />
          </Box>
        ) : null}
        <GrommetHeading
          level="2"
          margin={{ bottom: 'large' }}
          color="white"
          textAlign="center"
        >
          Yhteys&shy;tiedot
        </GrommetHeading>
        {isSmall ? null : <Logo />}
      </Box>

      <Paragraph margin="small" alignSelf={align} textAlign={textAlign}>
        Botski sijaitsee Hakaniemessä, Eläintarhanlahdella osoitteessa
        Eläintarhantie 10, Helsinki
      </Paragraph>
      <Paragraph
        margin={{ bottom: 'large', horizontal: 'small', top: 'medium' }}
        alignSelf={align}
        textAlign={textAlign}
      >
        Ota yhteyttä sähköpostilla{' '}
        <span style={{ textDecoration: 'underline' }}>moi@stadinbotski.fi</span>
      </Paragraph>
      <Box
        align="center"
        margin={{ bottom: 'none', horizontal: 'small', top: 'medium' }}
      >
        <Link
          href="https://www.instagram.com/stadinbotski/"
          passHref
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image width={75} height={75} src={instagram} alt="" />
        </Link>
      </Box>
    </Box>
  )
}
export default Contact

const Logo = () => (
  <Image
    src="/images/logo-white.svg"
    width={218.33}
    height={93.62}
    alt="logo"
  />
)
