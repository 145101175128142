import { Anchor, Box, Button, Nav, ResponsiveContext } from 'grommet'
import Image from 'next/image'
import Link from 'next/link'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { blueText } from 'utils/colors'

const routes = [
  {
    label: 'Vene',
    path: '/#vene'
  },
  {
    label: 'Hinnasto',
    path: '/#hinnasto'
  },
  {
    label: 'Yhteystiedot',
    path: '/#yhteystiedot'
  },
  {
    label: 'Vuokraa',
    path: '/vuokraa'
  }
]

const Logo = () => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  return isSmall ? (
    <Image src="/icons/sb-icon.svg" height={44} width={44} alt="logo" />
  ) : (
    <Image src="/images/logo.svg" height={73} width={172.25} alt="logo" />
  )
}

const OuterWrapper = styled.div<{
  outerWrapperHeight: number
  innerWrapperHeight: number
}>`
  display: flex;
  align-items: center;
  position: sticky;
  height: ${p => p.outerWrapperHeight}px;
  top: ${p => p.innerWrapperHeight - p.outerWrapperHeight}px;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
`

const Header = () => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  const innerWrapperHeight = isSmall ? 64 : 80
  const outerWrapperHeight = isSmall ? 80 : 120
  return (
    <OuterWrapper
      innerWrapperHeight={innerWrapperHeight}
      outerWrapperHeight={outerWrapperHeight}
    >
      <Box
        align="center"
        direction="row"
        pad="medium"
        background="white"
        height={`${innerWrapperHeight}px`}
        style={{ position: 'sticky', top: 0 }}
      >
        <Box margin={{ right: isSmall ? 'medium' : 'large' }}>
          <Link href="/" legacyBehavior>
            <Button plain icon={<Logo />} />
          </Link>
        </Box>
        <Navbar />
      </Box>
    </OuterWrapper>
  )
}
export default Header

const Navbar = () => {
  const size = useContext(ResponsiveContext)
  return (
    <Nav direction="row" justify="evenly" flex="grow" gap="large">
      {routes.map(({ label, path }) => (
        <LinkDecoration key={label}>
          <Anchor
            size={size}
            key={label}
            color="gray"
            label={label}
            href={path}
          />
        </LinkDecoration>
      ))}
    </Nav>
  )
}

const LinkDecoration = styled.div`
  margin-top: 10px; // offset content after (height + margin-top)
  a:after {
    content: '';
    display: flex;
    background: transparent;
    height: 4px;
    margin-top: 6px;
  }
  a:hover:after {
    content: '';
    margin-left: 50%;
    display: flex;
    transform: translateX(-50%);
    background: ${blueText};
    width: 120%;
    height: 4px;
    border-radius: 25px;
    margin-top: 6px;
  }
`
