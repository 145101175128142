import { Box, Grid } from 'grommet'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'

export type InstagramMedia = {
  id: string
  username: string
  media_url: string
  timestamp: string
  caption: string
  media_type: 'IMAGE' | ' VIDEO' | 'CAROUSEL_ALBUM'
  permalink: string
}

type Props = { data: InstagramMedia[] | null }

const InstagramFeed = ({ data }: Props) => {
  if (!data) return null
  const images = data.filter(img => img.media_type === 'IMAGE')
  return (
    <Styled>
      <Box
        background="#2348B2"
        pad={{ bottom: 'xlarge', horizontal: 'xsmall' }}
      >
        <Grid gap="xsmall" rows="medium" columns="medium">
          {images.map(i => (
            <Box key={i.id}>
              <Link
                href={i.permalink}
                target="_blank"
                rel="noopener noreferrer"
                className="instagram-link"
              >
                <Image
                  key={i.id}
                  src={i.media_url}
                  alt={i.caption}
                  fill={true}
                  sizes="(max-width: 786px) 100vw, (max-width: 1176px) 50vw, 33vw"
                  style={{ objectFit: 'cover' }}
                />
              </Link>
            </Box>
          ))}
        </Grid>
      </Box>
    </Styled>
  )
}

const Styled = styled.div`
  .instagram-link {
    height: 100%;
    position: relative;
    overflow: hidden;
    &:hover {
      filter: brightness(75%);
    }
  }
`

export default InstagramFeed
